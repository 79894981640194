/** @format */

import React from "react";
import styled from "styled-components";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <div>
        <WrapperForm>
          <form
            onSubmit={this.submitForm}
            action="https://formspree.io/p/1550943117425769484/f/contact"
            method="POST"
          >
            <input type="text" name="name" placeholder="Full Name" />
            <input type="email" name="email" placeholder="Email" />
            <textarea type="text" name="message" placeholder="Message" />

            {status === "SUCCESS" ? (
              <p>Thanks!</p>
            ) : (
              <button>
                <h1>Submit</h1>
              </button>
            )}
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
          </form>
        </WrapperForm>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

const WrapperForm = styled.div`
  background-color: #000000;
  margin: 3rem auto;
  max-width: 650;
  padding: 0 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  font-family: poppins;

  & > form > input {
    background-color: ghostwhite;
    border: none;
    width: 90%;
    height: 70px;
    margin-bottom: 15px;
    ::placeholder {
      font-size: 25px;
      padding-left: 20px;
      font-family: poppins;
    }
    @media screen and (max-width: 1024px) {
      ::placeholder {
        justify-content: center;
        font-size: 13px;
        padding-left: 20px;
        font-family: poppins;
        margin: 20px;
      }
    }
  }
  & > form > textarea {
    border: none;
    background-color: ghostwhite;

    width: 90%;
    height: 150px;
    margin-bottom: 5px;
    justify-content: center;

    ::placeholder {
      font-size: 25px;
      padding-left: 20px;
      font-family: poppins;
    }

    @media screen and (max-width: 1024px) {
      ::placeholder {
        font-size: 15px;
        padding: 20px;
        font-family: poppins;
      }
    }
  }

  & > form > button {
    font-family: poppins;
    width: 200px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    background-color: black;
    border-style: none;
    border: solid 2px white;
    color: white;
    margin-top: 10px;
    :hover {
      background-color: white;
      color: black;
    }
  }
  & > form > button > h1 {
    font-family: poppins;
    font-size: 20px;
  }

  & > form {
    border-style: none;
  }
`;
