/** @format */

import Footer from "./components/footer/Footer";
import NavBars from "./components/navBar/NavBars";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import styled from "styled-components";

function App() {
  return (
    <>
      <Wrapper>
        <Router>
          <NavBars />
          <Switch>
            <Route exact path="/" component={Home}>
              <Home />
            </Route>
            <Route exact path="/pitch-deck" component={About}>
              <About />
            </Route>
            <Route exact path="/contact" component={Contact}>
              <Contact />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </Wrapper>
    </>
  );
}

export default App;

const Wrapper = styled.div`
  background-color: #000000;
`;


