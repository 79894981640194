/** @format */

import React from "react";
import styled from "styled-components";
import { myPDF } from "../PDF/";
import { img1, img2, img3, img4, img5, img6, img7, img8 } from "../images";

const About = () => {
  return (
    <Wrapper>
      <DownloadBtn>
        <a href={myPDF} download="REUNION_pitch_deck.pdf">
          Download Pitch Deck
        </a>
      </DownloadBtn>
      <DeckDisplay src={img1} alt="img1" />
      <DeckDisplay src={img2} alt="img2" />
      <DeckDisplay src={img3} alt="img3" />
      <DeckDisplay src={img4} alt="img4" />
      <DeckDisplay src={img5} alt="img5" />
      <DeckDisplay src={img6} alt="img6" />
      <DeckDisplay src={img7} alt="img7" />
      <DeckDisplay src={img8} alt="img8" />
    </Wrapper>
  );
};

export default About;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: #000000;
`;

const DownloadBtn = styled.div`
  margin: 105px;
  background-color: #000000;
  border-style: none;
  & > a {
    color: white;
    border: 2px solid white;
    padding: 20px;
    font-weight: bold;

    font-family: Poppins;
    text-decoration: none;
    &:hover {
      background-color: white;
      color: black;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 800px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 15px;
  }
  @media screen and (max-width: 421px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 10px;
  }
  @media screen and (max-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 8px;
  }
  @media screen and (max-width: 332px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 8px;
  }
  @media screen and (max-width: 340px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 8px;
  }
  @media screen and (max-width: 318px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 5px;
  }
  @media screen and (max-width: 286px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 4px;
  }

  @media screen and (max-width: 280px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 200px;
    margin-right: 0px;
    padding: 0;
    & > a {
      color: white;
      border: 2px solid white;
      font-weight: bold;
      font-family: Poppins;
      text-decoration: none;
      &:hover {
        background-color: white;
        color: black;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    & > a {
      color: white;
      border: 2px solid white;
      padding: 10px;
      font-weight: bold;

      font-family: Poppins;
      text-decoration: none;
      &:hover {
        background-color: white;
        color: black;
        cursor: pointer;
      }
    }
  }
`;

const DeckDisplay = styled.img`
  width: 90rem;

  @media screen and (max-width: 1200px) and (max-width: 1440px) and (-webkit-min-device-pixel-ratio: 1) {
    width: 50rem;
  }

  @media screen and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    width: 70rem;
    padding-bottom: 5px;
  }

  @media screen and (max-width: 1125px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 50rem;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 800px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 25rem;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 400px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 15rem;
    padding-bottom: 5px;
  }
`;
