/** @format */

import React from "react";
import styled from "styled-components";
import { reunion } from "../images";

const Home = () => {
  return (
    <>
      <Wrapper>
        <Title src={reunion} />
        <Description>
          After accidentaly killing her rapist, a romantically awkard man
          uncovers the dark past of his high school crush.
        </Description>
        <iframe
          title="reunion"
          src="https://player.vimeo.com/video/432930538"
          width="100%"
          height="360px"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </Wrapper>
    </>
  );
};

export default Home;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85vh;
  width: 100vw;
  background-color: #000000;

  @media screen and (max-width: 1200px) and (max-width: 1600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    width: 100vw;
    background-color: black;
  }

  @media only screen and (max-width: 320px) and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 300vh;
    max-width: 900vh;
    background-color: black;
  }
`;

const Title = styled.img`
  width: 700px;
  padding-bottom: 30px;
  @media screen and (max-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    width: 500px;
  }

  @media only screen and (max-width: 375px) {
    width: 100px;
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 667px) {
    width: 300px;
    margin-top: 200px;
  }
  @media only screen and (max-width: 731px) {
    width: 300px;
    margin-top: 100px;
  }
  @media only screen and (max-width: 823px) {
    width: 300px;
    margin-top: 100px;
  }
  @media only screen and (max-width: 280px) {
    width: 200px;
    margin-top: 100px;
  }
`;

const Description = styled.p`
  margin: 0 32%;
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 0px;
  font-family: Poppins;
  font-weight: 200;

  /* ----------- Non-Retina Screens ----------- */

  @media screen and (max-width: 100px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    color: white;
    font-size: 18px;
    text-align: center;
    margin: 0;
  }

  @media screen and (max-width: 760px) {
    margin: 0 10%;
    color: white;
    font-size: 20px;
    text-align: center;
  }

  @media only screen and (max-width: 375px) {
    margin: 0%;
    font-size: 15px;
  }
  @media only screen and (max-width: 668px) {
    margin: 0 19%;
    font-size: 15px;
  }
`;
