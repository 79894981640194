/** @format */

import React from "react";
import styled from "styled-components";
import ContactForm from "../components/form/ContactForm";

const Contact = () => {
  return (
    <Wrapper>
      <Text>CONTACT</Text>
      <ContactForm />
    </Wrapper>
  );
};

export default Contact;

const Wrapper = styled.div`
  background-color: #0000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 90vh;
  margin-top: 230px;
  margin-bottom: 0;

  /* ----------- Non-Retina Screens ----------- */
  @media screen and (min-device-width: 1211px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    flex-direction: column;
  }
  @media only screen and (max-width: 414px) and (-webkit-min-device-pixel-ratio: 1) {
    flex-direction: column;

    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 0;
    margin-top: 40px;
  }
`;

const Text = styled.h1`
  position: relative;
  text-align: center;
  font-size: 100px;
  color: white;
  font-family: poppins;

  /* ----------- Non-Retina Screens ----------- */
  @media screen and (max-width: 1211px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    color: white;
    letter-spacing: 10px;
  }

  @media screen and (max-width: 800px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 45px;
    text-align: center;
  }
  @media screen and (min-device-width: 823px) {
    margin-top: 80px;
  }
  @media screen and (min-device-width: 320px) {
  }
  @media screen and (min-device-width: 812px) {
    padding-top: 70px;
  }
  @media screen and (min-device-width: 280px) {
    padding-top: 50px;
  }
`;
