/** @format */

import React from "react";
import styled from "styled-components";

const Footer = () => {
  return <Wrapper></Wrapper>;
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: black;
  color: white;
  height: 100px;
  width: 100%;
  /* ----------- Non-Retina Screens ----------- */
  @media screen and (max-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    color: white;
    height: 40px;
    width: 100vw;
  }
  /* ----------- Retina Screens ----------- */
  @media screen and (max-width: 375px) and (max-width: 400px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    color: white;
    height: 150px;
    width: 100%;
  }
`;
